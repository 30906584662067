import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import { Button, TextField, MuiThemeProvider, createMuiTheme } from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { getKeyBrandFileById, postKeyBrandFile, updateKeyBrandFile, deleteKeyBrandFile } from "../../../../api/keyBrandFiles"
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import { useSkeleton } from "../../../hooks/useSkeleton"
import { TextRow } from "react-placeholder/lib/placeholders"
import ReactPlaceholder from "react-placeholder"
import { alertError, alertSuccess } from "../../../../utils/logger"
import PreviewDialog from '../../../components/dialogs/PreviewDialog'
import { SERVER_URL } from '../../../../utils'
import DeleteIcon from '@material-ui/icons/Delete'

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F64E60'
    }
  }
})

function getEmptyKeyBrandFile() {
  return {
    name: '',
    fileURL: '',
    uploadedFileURL: '',
    order: null
  }
}

const placeholder = (
  <Card>
    <CardBody>
      <div id='tabs' className='m-7'>
        <TextRow showLoadingAnimation color='#CDCDCD' style={{ width: '100%', height: 50 }}/>
      </div>
    </CardBody>
  </Card>
)

export default function EditKeyBrandFilePage() {
  const [keyBrandFile, setKeyBrandFile] = useState(getEmptyKeyBrandFile())
  const [selectedFile, setSelectedFile] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openPreviewFile, setOpenPreviewFile] = useState(false)
  const keyBrandFileId = useParams().id
  const history = useHistory()
  const { isLoading: isLoadingData, disableLoading: disableLoadingData } = useSkeleton()

  useEffect(() => {
    if (!keyBrandFileId) {
      disableLoadingData()
      return
    }
    getKeyBrandFileById(keyBrandFileId).then(res => {
      if (res.status === 200) {
        setKeyBrandFile(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get keyBrandFile.' })
      history.push('/key-brand-files')
    })
  }, [keyBrandFileId, disableLoadingData, history])

  function saveKeyBrandFile() {
    if (!keyBrandFileId) {
      postKeyBrandFile(keyBrandFile, selectedFile).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Key brand file saved successfully.' })
          history.push('/key-brand-files')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save keyBrandFile.' })
      })
    } else {
      updateKeyBrandFile(keyBrandFileId, keyBrandFile, selectedFile).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes made have been saved successfully.' })
          history.push('/key-brand-files')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Changes could not be saved.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    setKeyBrandFile({ ...keyBrandFile, [element]: event.target.value })
  }

  if (isLoadingData)
    return <ReactPlaceholder showLoadingAnimation customPlaceholder={placeholder} />
  return (
    <>
      <Card>
        <CardHeader title='Edit key brand file'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`name`}
            label="Name"
            value={keyBrandFile.name}
            onChange={handleChange('name')}
            InputLabelProps={{
                shrink: true
            }}
            required
            margin="normal"
            variant="outlined"
          />
          <TextField
            id={`fileURL`}
            label="File URL"
            value={keyBrandFile.fileURL}
            onChange={handleChange('fileURL')}
            InputLabelProps={{
                shrink: true
            }}
            required
            margin="normal"
            variant="outlined"
          />
          <label htmlFor={"upload-file"}>
            <input
              style={{ display: 'none' }}
              id={"upload-file"}
              name={"upload-file"}
              type="file"
              onChange={(e) => {
                setSelectedFile(e.target.files[0])
              }}
            />
            <Button
              style={{ marginRight: '15px' }}
              color="secondary"
              component="span"
              variant="outlined">
              {((selectedFile || keyBrandFile.uploadedFileURL !== '') ? 'Change file' : 'Upload file')}
            </Button>
          </label>
          {(selectedFile || keyBrandFile.uploadedFileURL !== '') &&
            <>
              <Button
                onClick={() => setOpenPreviewFile(true)}
                style={{ marginRight: '15px' }}
                variant="outlined">
                {'Preview file'}
              </Button>
              <PreviewDialog
                title={'Preview file'}
                open={openPreviewFile}
                setOpen={setOpenPreviewFile}
                src={(selectedFile) ? URL.createObjectURL(selectedFile) : `${SERVER_URL}/${keyBrandFile.uploadedFileURL}`}
              />
              <Button
                onClick={() => {
                  setSelectedFile(null)
                  setKeyBrandFile({ ...keyBrandFile, uploadedFileURL: '' })
                }}>
                <DeleteIcon/>
              </Button>
            </>
          }
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/key-brand-files')}
        variant="outlined"
        style={{marginRight: '20px'}}>
        Back
      </Button>
      <Button
        onClick={() => saveKeyBrandFile()}
        variant="outlined"
        color="primary"
        style={{marginRight: '20px'}}>
        Save key brand file
      </Button>

      {keyBrandFileId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete key brand file
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this key brand file?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteKeyBrandFile(keyBrandFileId).then(res => {
              if (res.status === 204) {
                alertSuccess({  title: 'Deleted!', customMessage: 'Key brand file deleted successfully' })
                history.push('/key-brand-files')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete key brand file.' })
            })
          }}
        />
      </>}
    </>
  );
}

