import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls"
import Table, { dateFormatter, buttonsStyle } from '../../../components/tables/table'
import { Button, Tooltip } from "@material-ui/core"
import { useHistory } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import { deleteKeyBrandFile, getKeyBrandFiles, changeOrderKeyBrandFile } from "../../../../api/keyBrandFiles"
import { alertError, alertSuccess } from "../../../../utils/logger"
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

function getData(keyBrandFiles) {
  let data = []
  for (let i = 0; i < keyBrandFiles.length; ++i) {
    const elem = {}
    elem.order = keyBrandFiles[i].order
    elem.name = keyBrandFiles[i].name
    elem.createdAt = keyBrandFiles[i].createdAt
    elem.id = keyBrandFiles[i]._id
    data = data.concat(elem)
  }
  return data
}

export default function KeyBrandFilesPage() {
  const [data, setData] = useState([])
  const [keyBrandFileId, setKeyBrandFileId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function handleSort(file1, file2, order1, order2) {
    changeOrderKeyBrandFile(file1.id, order1).then(res => {
      if (res.status === 200) {
        changeOrderKeyBrandFile(file2.id, order2).then(resp => {
          if (resp.status === 200) {
            setRefresh(true)
          }
        })
      }
    })

  }

  function sortFormatter(cell) {
    if (!data.length) return <></>
    const index = data.findIndex(item => item.order === cell)
    const indexPrevious = data.findIndex(item => item.order === cell - 1)
    const indexNext = data.findIndex(item => item.order === cell + 1)
    return (<>
      {data[index].order === 0 ? <Button style={buttonsStyle} size="small" disabled={true}>{''}</Button> : <Tooltip title="Move up">
        <Button style={buttonsStyle} size="small"
          onClick={() => handleSort(data[index], data[indexPrevious], data[index].order - 1, data[index].order)}><ArrowUpwardIcon/></Button>
      </Tooltip>}
      {data[index].order === data.length - 1 ? <Button style={buttonsStyle} size="small" disabled={true}>{''}</Button> : <Tooltip title="Move down">
        <Button style={buttonsStyle} size="small"
          onClick={() => handleSort(data[index], data[indexNext], data[index].order + 1, data[index].order)}><ArrowDownwardIcon/></Button>
      </Tooltip>}
    </>)
  }

  function buttonFormatter(cell) {
    return (<>
      <Tooltip title="Edit">
        <Button style={buttonsStyle} size="small" onClick={() => history.push('/edit-key-brand-file/' + cell)}><EditIcon/></Button>
      </Tooltip>
      <Tooltip title="Delete">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setKeyBrandFileId(cell)
            setOpenConfirmDialog(true)
          }}>
          <DeleteIcon/>
        </Button>
      </Tooltip>
    </>)
  }

  const columns = [
    { dataField: 'order', text: '', formatter: sortFormatter },
    { dataField: 'name', text: 'Name' },
    { dataField: 'createdAt', text: 'Created at', formatter: dateFormatter },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getKeyBrandFiles().then((res) => {
      if (res.status === 200) {
        setData(getData(res.data))
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get key brand files.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Key brand files list'>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push('/edit-key-brand-file')}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
            title={'Are you sure you want to delete this key brand file?'}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteKeyBrandFile(keyBrandFileId).then(res => {
                if (res.status === 204) {
                  alertSuccess({  title: 'Deleted!', customMessage: 'Key brand file successfully deleted.' })
                  setRefresh(true)
                }
              }).catch(error => {
                alertError({ error: error, customMessage: 'Could not delete key brand file.' })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}

