import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControl,
  InputLabel,
  FormHelperText
} from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { deleteUser, getUserById, postUser, updateUser } from "../../../../api/user"
import { getMarkets } from "../../../../api/markets"
import { useSkeleton } from "../../../hooks/useSkeleton"
import { alertError, alertSuccess } from "../../../../utils/logger"
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import CreatableSelect from 'react-select/creatable'

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F64E60'
    }
  }
})

function getEmptyInformed() {
  return {
    fullName: '',
    email: '',
    role: 'informed',
    markets: []
  }
}

const getDataSelect = (markets) => {
  const data = []
  for (const market of markets) {
    let elem = {}
    elem.label = market.name
    elem.value = market._id
    data.push(elem)
  }
  return data
}

export default function EditInformedsPage() {
  const [informed, setInformed] = useState(getEmptyInformed())
  const [markets, setMarkets] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const informedId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {

    getMarkets().then(resp => {
      if (resp.status === 200) {
        setMarkets(getDataSelect(resp.data))
        if (!informedId) {
          disableLoadingData()
        }
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get markets.' })
      history.push('/products')
    })

    if (!informedId) {
      disableLoadingData()
      return
    }
    getUserById(informedId).then(res => {
      if (res.status === 200) {
        const user = res.data
        setInformed(user)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get area manager.' })
      history.push('/informeds')
    })
  }, [informedId, disableLoadingData, history])

  function saveInformed() {
    if (!informedId) {
      postUser(informed).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Informed successfully created.' })
          history.push('/informeds')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save area manager.' })
      })
    } else {
      updateUser(informedId, informed).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/informeds')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    setInformed({ ...informed, [element]: event.target.value })
  }

  const handleChangeMarkets = (event) => {
    setInformed({ ...informed, markets: event })
  }

  const handleCreate = (event) => {
    const newMarkets = markets
    const newTag = { value: 'new-' + event, label: event }
    newMarkets.push(newTag)
    setMarkets(newMarkets)
    setInformed({ ...informed, markets: informed.markets.concat(newTag) })
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit area manager'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`fullName`}
            label="Full name"
            value={informed.fullName}
            onChange={handleChange('fullName')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            id={`email`}
            label="Email"
            value={informed.email}
            onChange={handleChange('email')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label">Markets</InputLabel>
            <CreatableSelect
              isClearable
              isMulti
              value={informed.markets}
              onChange={handleChangeMarkets}
              onCreateOption={handleCreate}
              options={markets}
            />
            <FormHelperText>Select all markets</FormHelperText>
          </FormControl>
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('informeds')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveInformed()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save informed
      </Button>
      {informedId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete informed
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this area manager?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteUser(informedId).then(res => {
              if (res.status === 204) {
                alertSuccess({ title: 'Deleted!', customMessage: 'Area manager deleted successfully' })
                history.push('/informeds')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete area manager.' })
            })
          }}
        />
      </>}
    </>
  );
}
