import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  FormHelperText
} from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { deleteUser, getUserById, postUser, updateUser } from "../../../../api/user"
import { getMarkets } from "../../../../api/markets"
import { useSkeleton } from "../../../hooks/useSkeleton"
import { alertError, alertSuccess } from "../../../../utils/logger"
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import CreatableSelect from 'react-select/creatable'

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F64E60'
    }
  }
})

function getEmptyUser() {
  return {
    fullName: '',
    email: '',
    role: 'client',
    password: '',
    repeatPassword: '',
    verified: false,
    validated: false,
    markets: []
  }
}

const getDataSelect = (markets) => {
  const data = []
  for (const market of markets) {
    let elem = {}
    elem.label = market.name
    elem.value = market._id
    data.push(elem)
  }
  return data
}

export default function EditUsersPage() {
  const [user, setUser] = useState(getEmptyUser())
  const [markets, setMarkets] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const userId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    getMarkets().then(resp => {
      if (resp.status === 200) {
        setMarkets(getDataSelect(resp.data))
        if (!userId) {
          disableLoadingData()
        }
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get markets.' })
      history.push('/products')
    })
    if (!userId) {
      disableLoadingData()
      return
    }
    getUserById(userId).then(res => {
      if (res.status === 200) {
        const user = res.data
        delete user.password
        setUser(user)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get user.' })
      history.push('/users')
    })
  }, [userId, disableLoadingData, history])

  function saveUser() {
    if (user.password !== user.repeatPassword) {
      alertError({ error: null, customMessage: 'Passwords do not match.' })
      return
    }
    delete user.repeatPassword
    if (!userId) {
      postUser(user).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'User successfully created.' })
          history.push('/users')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save user.' })
      })
    } else {
      updateUser(userId, user).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/users')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    setUser({ ...user, [element]: event.target.value })
  }

  const handleChangeMarkets = (event) => {
    setUser({ ...user, markets: event })
  }

  const handleCreate = (event) => {
    const newMarkets = markets
    const newTag = { value: 'new-' + event, label: event }
    newMarkets.push(newTag)
    setMarkets(newMarkets)
    setUser({ ...user, markets: user.markets.concat(newTag) })
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit User'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`fullName`}
            label="Full name"
            value={user.fullName}
            onChange={handleChange('fullName')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            id={`email`}
            label="Email"
            value={user.email}
            onChange={handleChange('email')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            id={`password`}
            label="Password"
            value={user.password}
            onChange={handleChange('password')}
            InputLabelProps={{
              shrink: true
            }}
            type="password"
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            id={`repeatPassword`}
            label="Repeat password"
            value={user.repeatPassword}
            onChange={handleChange('repeatPassword')}
            InputLabelProps={{
              shrink: true
            }}
            type="password"
            margin="normal"
            variant="outlined"
            required
          />
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label">Markets</InputLabel>
            <CreatableSelect
              isClearable
              isMulti
              value={user.markets}
              onChange={handleChangeMarkets}
              onCreateOption={handleCreate}
              options={markets}
            />
            <FormHelperText>Select all markets</FormHelperText>
          </FormControl>
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={user.verified} onChange={() => setUser({ ...user, verified: !user.verified })} name="checkVerified" />}
            label="Verified"
          />
          <FormControlLabel
            control={<Checkbox checked={user.validated} onChange={() => setUser({ ...user, validated: !user.validated })} name="checkValidated" />}
            label="Validated"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/users')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveUser()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save user
      </Button>
      {userId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete user
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this user?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteUser(userId).then(res => {
              if (res.status === 204) {
                alertSuccess({ title: 'Deleted!', customMessage: 'User deleted successfully' })
                history.push('/users')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete user.' })
            })
          }}
        />
      </>}
    </>
  );
}
