import { API, authClient } from '../../utils/index'
import { uploadSingleFile } from '../files'

// Get all key brand files
export const getKeyBrandFiles = () => {
    return authClient().get(`${API}/key-brand-files`)
}

// Get key brand file by id
export const getKeyBrandFileById = (id) => {
    return authClient().get(`${API}/key-brand-files/${id}`)
}

// Update key brand file
export const updateKeyBrandFile = async (id, keyBrandFile, file) => {
    if (file) {
        const response = await uploadSingleFile(file, 'keyBrandFiles')
        keyBrandFile.uploadedFileURL = response.data.fileURL
    }
    return authClient().put(`${API}/key-brand-files/${id}`, keyBrandFile)
}

// Change order key brand file
export const changeOrderKeyBrandFile = async (id, order) => {
    return authClient().put(`${API}/key-brand-files/change-order/${id}?order=${order}`)
}

// Save key brand file
export const postKeyBrandFile = async (keyBrandFile, file) => {
    if (file) {
        const response = await uploadSingleFile(file, 'keyBrandFiles')
        keyBrandFile.uploadedFileURL = response.data.fileURL
    }
    return authClient().post(`${API}/key-brand-files`, keyBrandFile)
}

// Delete key brand file
export const deleteKeyBrandFile = (id) => {
    return authClient().delete(`${API}/key-brand-files/${id}`)
}

